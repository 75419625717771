@use 'sass:map';
@use '@angular/material' as mat;

@import 'src/styles/config';


@mixin color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark-theme: map.get($color-config, 'is-dark');

  @if $is-dark-theme {
    .ctmc-search-open {
      background-color: #424242 !important;
      border-color: color('light-text') !important;
      filter: drop-shadow(0 0 3px lightgray) !important;

      a:visited, a:active, a {
        color: color('light-text') !important;
      }
    }
    .search-button-wrapper {
      background-color: rgba(0, 0, 0, 0.37) !important;
    }
    .vertical-line {
      background-color: rgba(255, 255, 255, 0.17) !important;
    }
  } @else {
    .ctmc-search-open {
      background: lightgray;
      border-color: color('dark-text');
      filter: drop-shadow(0 0 3px black);

      a:visited, a:active, a {
        color: color('dark-text');
      }
    }
    .search-button-wrapper {
      background-color: rgba(0, 0, 0, 0.07);
    }
    .vertical-line {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }
}
